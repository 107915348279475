<template>
  <div class="d-table w-100">
    <template v-if="state === EMPTY_STATE">
      <v-text-field
        :label="label"
        :error="Boolean(errorMessage)"
        :messages="errorMessage"
        :data-cy="`${dataCy}-empty-text-field`"
        @click="showEditor"
      />
    </template>
    <template v-else-if="state === EDIT_STATE">
      <v-label>{{ label }}</v-label>
      <vue-editor
        ref="editor"
        v-model="content"
        :data-cy="`${dataCy}-editor`"
      />
      <div class="mt-2">
        <v-btn
          rounded
          depressed
          outlined
          color="primary"
          class="float-right ml-2"
          @click.prevent="onCancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          depressed
          color="primary"
          class="float-right"
          :data-cy="`${dataCy}-save-button`"
          @click.prevent="onSave"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </template>
    <template v-else-if="state === SAVED_STATE">
      <v-label>{{ label }}</v-label>
      <!-- eslint-disable vue/no-v-html -->
      <span
        class="description"
        :data-cy="`${dataCy}-saved-html`"
        @click="showEditor"
        v-html="sanitizeHtml(content)"
      />
      <!-- eslint-enable vue/no-v-html -->
      <v-divider />
    </template>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import DOMPurify from 'dompurify'

const EMPTY_STATE = 'EMPTY'
const EDIT_STATE = 'EDIT'
const SAVED_STATE = 'SAVED'

export default {
  components: {
    VueEditor
  },
  props: {
    label: {
      type: String,
      required: true
    },
    dataCy: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      content: '',
      contentBeforeEdit: '',
      state: EMPTY_STATE,
      EMPTY_STATE,
      EDIT_STATE,
      SAVED_STATE
    }
  },
  methods: {
    showEditor () {
      this.contentBeforeEdit = this.content
      this.state = EDIT_STATE
      this.$nextTick(() => {
        this.$refs.editor.quill.focus()
      })
    },
    onSave () {
      this.$emit('content-change', this.content)
      if (this.content) {
        this.state = SAVED_STATE
      } else {
        this.state = EMPTY_STATE
      }
    },
    onCancel () {
      this.state = this.contentBeforeEdit ? SAVED_STATE : EMPTY_STATE
      this.content = this.contentBeforeEdit
    },
    sanitizeHtml (html) {
      return DOMPurify.sanitize(html)
    }
  }
}
</script>
